import React, { useMemo } from 'react';
import { session_user_uuid, session_parent_uuid } from '../session.js';
import { post_data, get_page_content } from '../API_Calls.js';
import { createColumnHelper, ColumnDef, CellContext } from '@tanstack/react-table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CustomCheckbox from '../../Components/CustomCheckbox';
import { sendDataToParent } from './Iframe_Handler.js';
import { Badge } from 'react-bootstrap';

export type ssasContent = {
  ssas_dashboard_tab_title: string;
  ssas_dashboard_tab_table1_title: string;
  ssas_dashboard_tab_table1_column: string;
  ssas_dashboard_tab_table2_title: string;
  ssas_dashboard_tab_table2_column: string;
  ssas_schedule_tab_title: string;
  ssas_schedule_tab_table_column: string;
  ssas_contacts_tab_title: string;
  ssas_contacts_tab_table_column: string;
  ssas_event_tab_title: string;
  ssas_event_tab_table_column: string;
  ssas_vessel_tab_title: string;
  ssas_vessel_tab_table_column: string;
}

export let defaultSsasContent: ssasContent = {
  ssas_dashboard_tab_title: "",
  ssas_dashboard_tab_table1_title: "",
  ssas_dashboard_tab_table1_column: "",
  ssas_dashboard_tab_table2_title: "",
  ssas_dashboard_tab_table2_column: "",
  ssas_schedule_tab_title: "",
  ssas_schedule_tab_table_column: "",
  ssas_contacts_tab_title: "",
  ssas_contacts_tab_table_column: "",
  ssas_event_tab_title: "",
  ssas_event_tab_table_column: "",
  ssas_vessel_tab_title: "",
  ssas_vessel_tab_table_column: "",
};

export async function fetchSSASContent(){
    const page_content = await get_page_content("ssas");
    defaultSsasContent = page_content;
    return defaultSsasContent;
}

export interface SSASAlert {
  alert_archive_uuid: string;
  alert_uuid: string;
  vessels_uuid: string;
  current_status: string;
  schedule_uuid: string;
  schedule_date: Date | null;
  schedule_by: string;
  outcome: string;
  archive_date: Date | null;
  lat: Double;
  lon: Double;
  geopoint: string;
  vessel_name: string;
  vessel_imo: string;
  vessel_mmsi: string;
  /* Local */
  expand: boolean;
}

export async function getAlertsData(alert_uuids, vessel_uuids){
     const parameters = {
        "item_uuid" : alert_uuids,
        "vessel_uuid" : vessel_uuids,
        "user_uuid" :  null,
        "parent_uuid" : session_parent_uuid,
        "include_deleted" : true
    }

    const res = await post_data("/getSSASAlert_List", parameters);
    let res_data: SSASAlert[] = res["ssasAlerts_list"].map(alert => ({
          ...alert,
          expand: false // default to false if not defined
      }));
    return res_data;
}
export async function getAlertsArchiveData(alert_uuids, vessel_uuids){
        const parameters = {
        "item_uuid" : alert_uuids,
        "vessel_uuid" : vessel_uuids,
        "user_uuid" :  null,
        "parent_uuid" : session_parent_uuid,
        "include_deleted" : false
    }

    const res = await post_data("/getSSASAlertArchive_List", parameters);
    let res_data: SSASAlert[] = res["ssasAlerts_list"].map(alert => ({
          ...alert,
          expand: false // default to false if not defined
      }));
    return res_data;
}

export interface SSASContact {
 archive_contact_uuid: string;
 contact_uuid: string;
 user_name: string;
 user_company: string;
 user_role: string;
 user_email: string;
 user_phone: string;
 user_sms: string;
 active_email: bool;
 active_screen: bool;
 test_email: bool;
 test_screen: bool;
 internal_bool: bool;
 master: bool;
 /* Local */
 expand: boolean;
 relation: SSASAffiliation[];
}

export async function getContactData(contact_uuids, vessel_uuids){
     const parameters = {
        "item_uuid" : contact_uuids,
        "vessel_uuid" : vessel_uuids,
        "user_uuid" :  null,
        "parent_uuid" : session_parent_uuid,
        "include_deleted" : false
    }

    const res_analysis = await post_data("/getSSASContact_List", parameters);
    let res_data: SSASContact[] = res_analysis["ssasContact_list"].map(contact => ({
        ...contact,
        expand: false
    }));

    return res_data;
}

export interface SSASEvent {
 events_logs_uuid: string;
 alert_details: SSASAlert;
 contact_details: SSASContact_New;
 log_date: Date | null;
 log_type: string;
 log_mode: string;
 log_details: string;
 completed: Date | null;
 completed_by: string;
}

export async function getEventData(event_uuids, vessel_uuids){
    const parameters = {
        "item_uuid" : event_uuids,
        "vessel_uuid" : vessel_uuids,
        "user_uuid" :  null,
        "parent_uuid" : session_parent_uuid,
        "include_deleted" : false
    }

    const res_analysis = await post_data("/getSSASEventLog_List", parameters);
    let res_data: SSASEvent[] = res_analysis["ssasEventLog_list"];

    return res_data;
}

export interface SSASAffiliation {
    affiliation_uuid: string;
    contact_uuid: string;
    alert_uuid: string;
    vessel_uuid: string;
    priority_level: number;
}
export async function getAffiliationData(alert_uuids, contact_uuids, vessel_uuids){
    const parameters = {
       "alert_uuid": alert_uuids,
       "contact_uuid": contact_uuids,
       "vessel_uuid": vessel_uuids
    };

    const res = await post_data("/getSSASAffiliation_List", parameters);
    let res_data: SSASAffiliation[] = res["ssasAffiliation_list"];

    return res_data;
}

const flyToMobile = (position: number[]) => {
  console.log('Fly to mobile', position);
  sendDataToParent({ item:' fly_to_location', value: position})
};
export function getRelevantSSAS_Status(code, type) {
    if (type === "schedule") {
        switch (code) {
            case 0:
                return "NOT APPLICABLE";
            case 1:
                return "PENDING";
            case 2:
                return "SUCCESSFUL";
            case 3:
                return "UNSUCCESSFUL";
            default:
                return "UNKNOWN";
        }
    }else if (type === "event") {
         switch (code) {
            case 0:
                 return "NOT APPLICABLE";
             case 1:
                 return "LIVE";
             case 2:
                 return "TEST";
             default:
                 return "UNKNOWN";
         }
     }else if (type === "vessels") {
           switch (code) {
              case 0:
                   return "NOT ENABLED";
               case 1:
                   return "SSAS ACTIVE";
               case 1:
                   return "SSAS FIRING IN TEST MODE";
               case 2:
                   return "SSAS FIRING";
               default:
                   return "UNKNOWN";
           }
       }

    return "UNKNOWN";
}

export function getAlertStatusElement(status_string){
    if(status_string === "SSAS FIRING IN TEST MODE"){
        return <Badge bg='warning'>SSAS FIRING IN TEST MODE</Badge>;
    }else if(status_string === "SSAS FIRING"){
        return <Badge bg='danger'>SSAS FIRING</Badge>;
    } else if (status_string === "SSAS ACTIVE"){
        return <Badge bg='success'>SSAS ACTIVE</Badge>;
    } else if (status_string === "SSAS ACTIVATION REQUESTED"){
        return <Badge bg='secondary'>SSAS ACTIVATION REQUESTED</Badge>;
    } else if (status_string === "SSAS IN TEST MODE"){
        return <Badge bg='primary'>SSAS IN TEST MODE</Badge>;
    } else if (status_string === "NOT ENABLED"){
        return <Badge bg='light'>NOT ENABLED</Badge>;
    } else{
        return <Badge bg='dark'>UNKNOWN</Badge>;
    }
}

export function getScheduleStatusElement(status_string){
    if(status_string === "NOT APPLICABLE"){
        return <Badge bg='secondary'>NOT APPLICABLE</Badge>;
    }else if(status_string === "PENDING"){
        return <Badge bg='primary'>PENDING</Badge>;
    }else if(status_string === "SUCCESSFUL"){
        return <Badge bg='success'>SUCCESSFUL</Badge>;
    }else if(status_string === "UNSUCCESSFUL"){
        return <Badge bg='danger'>UNSUCCESSFUL</Badge>;
    }else if(status_string === "TEST UNDERWAY"){
        return <Badge bg='warning'>UNSUCCESSFUL</Badge>;
    }else{
        return <Badge bg='dark'>UNKNOWN</Badge>;
    }
}

export function getLogStatusElement(status_string){
    if(status_string === "NOT APPLICABLE"){
        //return <span className='badge bg-secondary'>NOT APPLICABLE</span>;
        return <Badge bg='secondary'>NOT APPLICABLE</Badge>;
    }else if(status_string === "LIVE"){
        //return <span className='badge bg-danger'>LIVE</span>;
        return <Badge bg='danger'>LIVE</Badge>;
    }else if(status_string === "TEST"){
        //return <span className='badge bg-warning'>TEST</span>;
        return <Badge bg='warning'>TEST</Badge>;
    }else {
        //return <span className='badge bg-dark'>UNKNOWN</span>;
        return <Badge bg='dark'>UNKNOWN</Badge>;
    }
}

export function getAlertTableData(data, columns_names) {
    const columnHelper = createColumnHelper();
    const headers = columns_names.split("|");

    const active_alerts_columns = [
        columnHelper.accessor('vessel_name', {
            header: headers[0] ?? 'VESSEL',
            cell: info => info.getValue(),
        }),
        columnHelper.accessor('current_status', {
            header: <span className="center_header">{headers[1] ?? 'STATUS'}</span>,
            filterFn: 'equals',
            cell: info => getAlertStatusElement(info.getValue()?.toUpperCase()),
        }),
        columnHelper.accessor('locate', {
            header: <span className="right_header">{headers[2] ?? 'LOCATE'}</span>,
            enableColumnFilter: false,
            cell: info => (
                <p class="align-right"><button className="btn fas pt1-5"
                    onClick={() => flyToMobile([info.row.original.lon, info.row.original.lat])}
                    title="Centre map on vessel">
                    <FontAwesomeIcon icon={['fas', 'crosshairs']} />
                </button></p>
            ),
        }),
    ];

    return active_alerts_columns;
}

export function getScheduleTableData(data, is_dash, callbacks, columns_names) {
    const columnHelper = createColumnHelper();
    const headers = columns_names.split("|");
    let schedule_columns = [];

    if(!is_dash){
       schedule_columns.push(columnHelper.accessor('expand', {
         header: 'EXPAND',
         enableColumnFilter: false,
         cell: info => {
           const isExpanded = info.getValue(); // Get the current value for this cell
           return (
             <p className="align-center">
             <button className="btn fas pt1-5" title={isExpanded ? "Collapse" : "Expand"}
               onClick={() => callbacks.toggleExpandTestSchedule(info.row.original.alert_uuid)} >
               <FontAwesomeIcon icon={['fal', isExpanded ? 'square-minus' : 'square-plus']} />
             </button></p>
           );
         }
       }));
    }
    schedule_columns.push(columnHelper.accessor('schedule_date', {
       header: 'DATE (UTC)',
       filterFn: 'includesString',
       cell: info => info.getValue().replace('T', ' ').substring(0, 16),
    }));
    schedule_columns.push(columnHelper.accessor('vessel_name', {
       header: 'VESSEL',
       filterFn: 'includesString',
       cell: info => info.getValue(),
    }));

    if(!is_dash){
    　 schedule_columns.push(columnHelper.accessor('scheduled_by', {
         header: 'Scheduled by',
         filterFn: 'includesString',
         cell: info => info.getValue(),
       }));
    }
    schedule_columns.push(columnHelper.accessor('outcome', {
        header: <span className="center_header">OUTCOME</span>,
       filterFn: 'equals',
       cell: info => {
        return getScheduleStatusElement(info.getValue());
       }
    }));
    schedule_columns.push(columnHelper.accessor('locate', {
       header: <span className="right_header">LOCATE</span>,
       enableColumnFilter: false,
       cell: info => <p class="align-right"><button className="btn fas pt1-5" onClick={() => flyToMobile([info.row.original.lon, info.row.original.lat])} title="Centre map on vessel" value="mobile_find">
           <FontAwesomeIcon icon="crosshairs"/>
       </button></p>,
    }));

    return schedule_columns;
}
export function internal_checkbox(){
    console.log("internal changed");
}
export function getContactsTableData(data, callbacks, columns_names){
    const columnHelper = createColumnHelper();
    const headers = columns_names.split("|");

    const contacts_columns = [
      columnHelper.accessor('expand', {
        header: 'Expand',
        enableColumnFilter: false,
        cell: info => {
          const isExpanded = info.getValue(); // Get the current value for this cell
          return (<p className="align-center">
            <button className="btn fas pt1-5" title={isExpanded ? "Collapse" : "Expand"}
              onClick={() => callbacks.toggleExpandContact(info.row.original.contact_uuid)} >
              <FontAwesomeIcon icon={['fal', isExpanded ? 'square-minus' : 'square-plus']} />
            </button></p>
          );
      }}),
      columnHelper.accessor('internal_bool', {
          header: 'Internal',
          enableColumnFilter: false,
          cell: info => {
            const row_id = info.row.original.row_id;
            let disable_box = false;
            let value = info.getValue();

            if(value) value = 1;
            else value = 0;
            //disable_box = true;
            //value = 0

            return (<p className="align-center"><CustomCheckbox breadcrumbs={""} id={row_id + "internal"} initialState={value}
            three_states={false} onChange={internal_checkbox} disabled={disable_box} /></p>);
        }}),
      columnHelper.accessor('master', {
        header: 'Master',
        enableColumnFilter: false,
        cell: info => {
           const row_id = info.row.original.row_id;
           let disable_box = false;
           let value = info.getValue();
           if(value) value = 1;
           else value = 0;
           //disable_box = true;
           //value = 0
           return (<p className="align-center"><CustomCheckbox breadcrumbs={""} id={row_id + "master"} initialState={value}
           three_states={false} onChange={internal_checkbox} disabled={disable_box} /></p>);
      }}),
      columnHelper.accessor('user_name', {
          header: 'Name',
          filterFn: 'includesString',
          cell: info => info.getValue()
        }),
      columnHelper.accessor('user_company', {
        header: 'Company',
        filterFn: 'includesString',
        cell: info => info.getValue()
      }),
      columnHelper.accessor('user_role', {
        header: 'Position',
        cell: info => info.getValue()
      }),
      columnHelper.accessor('user_email', {
        header: 'Email',
        cell: info => info.getValue()
      }),
      columnHelper.accessor('user_phone', {
        header: 'Phone',
        cell: info => info.getValue()
      }),
      columnHelper.accessor('user_sms', {
        header: 'SMS / WhatsApp',
        cell: info => info.getValue()
      }),
      columnHelper.accessor('active_email', {
        header: 'Active Email',
           enableColumnFilter: false,
        cell: info => {
           const row_id = info.row.original.row_id;
           let disable_box = false;
           let value = info.getValue();
           if(value) value = 1;
           else value = 0;
           //disable_box = true;
           //value = 0
           return (<p className="align-center"><CustomCheckbox breadcrumbs={""} id={row_id + "active_email"} initialState={value}
           three_states={false} onChange={internal_checkbox} disabled={disable_box} /></p>);
      }}),

      columnHelper.accessor('active_screen', {
        header: 'Active Screen',
        enableColumnFilter: false,
        cell: info => {
            const row_id = info.row.original.row_id;
            let disable_box = false;
            let value = info.getValue();
            if(info.row.original.master !== true){
                disable_box = true;
                value = 0;
            }
            else if(value) value = 1;
            else value = 0;

            return (<p className="align-center"><CustomCheckbox breadcrumbs={""} id={row_id + "active_screen"} initialState={value}
            three_states={false} onChange={internal_checkbox} disabled={disable_box} /></p>);
        }}),

      columnHelper.accessor('test_email', {
        header: 'Test Email',
        enableColumnFilter: false,
        cell: info => {
             const row_id = info.row.original.row_id;
             let disable_box = false;
             let value = info.getValue();
             if(info.row.original.master !== true){
                 disable_box = true;
                 value = 0;
             }
             else if(value) value = 1;
             else value = 0;

             return (<p className="align-center"><CustomCheckbox breadcrumbs={""} id={row_id + "test_email"} initialState={value}
             three_states={false} onChange={internal_checkbox} disabled={disable_box} /></p>);
        }}),
      columnHelper.accessor('test_screen', {
        header: 'Test Screen',
        enableColumnFilter: false,
        cell: info => {
            const row_id = info.row.original.row_id;
             let disable_box = false;
             let value = info.getValue();
             if(info.row.original.master !== true){
                 disable_box = true;
                 value = 0;
             }
             else if(value) value = 1;
             else value = 0;

             return (<p className="align-center"><CustomCheckbox breadcrumbs={""} id={row_id + "test_screen"} initialState={value}
             three_states={false} onChange={internal_checkbox} disabled={disable_box} /></p>);
        }}),
      columnHelper.accessor('delete_able', {
        header: 'Delete',
        enableColumnFilter: false,
        cell: info => {return info.getValue() ? (
          <button className="delete_link" onClick={() => callbacks.deleteContact(info.row.original.contact_uuid)}>DELETE</button>
        ) : null;},
      })
    ];

    return contacts_columns;
};

export function getEventsLogsTableData(data, columns_names){
    const columnHelper = createColumnHelper();
    const headers = columns_names.split("|");

    const events_columns = [
        columnHelper.accessor('log_date', {
            header: 'LOG DATE',
            cell: info => info.getValue().replace('T', ' ').substring(0, 16),
        }),
        columnHelper.accessor('log_type', {
            header: 'LOG TYPE',
            cell: info => info.getValue(),
        }),
        columnHelper.accessor('log_mode', {
            header: <span className="center_header">MODE</span>,
            filterFn: 'equals',
            cell: info => getLogStatusElement(info.getValue()),
        }),
        columnHelper.accessor('contact_details.user_email', {
            header: 'EMAIL',
            cell: info => info.getValue(),
        }),
        columnHelper.accessor('contact_details.user_name', {
            header: 'NAME',
            cell: info => info.getValue(),
        }),
        columnHelper.accessor('contact_details.user_company', {
            header: 'COMPANY',
            cell: info => info.getValue(),
        }),
        columnHelper.accessor('alert_details.current_status', {
            header: 'OUTCOME',
            cell: info => getAlertStatusElement(info.getValue()),
        }),
        columnHelper.accessor('alert_details.vessel_name', {
            header: 'VESSEL',
            cell: info => info.getValue(),
        }),
        columnHelper.accessor('alert_details.locate', {
            header: <span className="right_header">LOCATE</span>,
            enableColumnFilter: false,
            cell: info => (
                <p class="align-right"><button className="btn fas pt1-5" title="Center map on vessel"
                    onClick={() => flyToMobile([info.row.original.alert_details.lon, info.row.original.alert_details.lat])} >
                    <FontAwesomeIcon icon={['fas', 'crosshairs']} />
                </button></p>
            ),
        }),
    ];

    return events_columns;
};

export function getVesselsTableData(data, expand_contacts, expand_uuid, columns_names){
    const columnHelper = createColumnHelper();
    const headers = columns_names.split("|");

    const vessels_columns = [
        columnHelper.accessor('vessel_name', {
            header: 'VESSEL',
            cell: info => info.getValue(),
        }),
        columnHelper.accessor('current_status', {
            header: <span className="center_header">STATUS</span>,
            filterFn: 'equals',
            cell: info => getAlertStatusElement(info.getValue()),
        }),
    ];
    /*if(expand_contacts){
        vessels_columns.push(
        columnHelper.accessor('vessel', {
            header: 'VESSEL',
            cell: info => info.getValue(),
        }));
    }*/
    vessels_columns.push(
        columnHelper.accessor('schedule_date', {
         header: 'NEXT TEST (UTC)',
         cell: info => info.getValue().replace('T', ' ').substring(0, 16),
        }),
        columnHelper.accessor('locate', {
         header: <span className="right_header">LOCATE</span>,
         enableColumnFilter: false,
         cell: info => (
             <p class="align-right"><button className="btn fas pt1-5" title="Center map on vessel"
                 onClick={() => flyToMobile([info.row.original.lon, info.row.original.lat])} >
                 <FontAwesomeIcon icon={['fas', 'crosshairs']} />
             </button></p>
         ),
        })
    );

    return vessels_columns;
};
