import React, { useEffect, useState, useRef, useMemo, useCallback } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { exportToCSV, exportToExcel } from '../Javascript/General.js';
import { matchSorter } from 'match-sorter';
import {
  createColumnHelper,
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
  getPaginationRowModel,
  getFilteredRowModel,
  ColumnFiltersState,
  flexRender,
  ColumnDef,
  Row,
} from '@tanstack/react-table';

function useDebounce<T>(value: T, delay: number): T {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);
    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}

type TextFilterFn<T> = (rows: Row<T>[], columnId: keyof T, filterValue: string) => Row<T>[];
type Association = [string, string];
type DropdownValue = [string, Association[]];

interface CustomTableProps<T> {
  breadcrumbs: string;
  columns: ColumnDef<T, any>[];
  data: T[];
  onRowClick?: (row: T) => void;
  rows_displayed: number;
  dropdown_placeholder: string;
  expand_element: ((row_id: string, more_info:T | null) => void) | null;
}

const CustomTable = <T extends object>({ breadcrumbs, columns, data, onRowClick, rows_displayed, dropdown_placeholder, expand_element}: CustomTableProps<T>) => {
  const [filters, setFilters] = useState<ColumnFiltersState>([]);
  const [tableName, setTableName] = useState<string>("");
  const memoizedFilters = useMemo(() => filters, [filters]);
  const debouncedFilters = useDebounce(memoizedFilters, 300);
  const memoizedData = useMemo(() => data, [data]);
  const memoizedColumns = useMemo(() => columns, [columns]);
  const dropdownValue: DropdownValue[] = [];

  useEffect(() => {
    let name = breadcrumbs.split('|');
    setTableName(name[name.length - 1]);
  },[]);

  const initialState = {
    pagination: {
      pageSize: rows_displayed
    }
  };

  const table = useReactTable({
      data: memoizedData,
      columns: memoizedColumns,
      getCoreRowModel: getCoreRowModel(),
      getSortedRowModel: getSortedRowModel(),
      getPaginationRowModel: getPaginationRowModel(),
      debugTable: false,
      getFilteredRowModel: getFilteredRowModel(),  // No arguments here
      state: {
        columnFilters: debouncedFilters,
      },
      onColumnFiltersChange: setFilters,
      initialState,
    });

  const handleRowClick = useCallback((row: T) => {
      if (onRowClick) onRowClick(row);
    }, [onRowClick]);

  const handleFiltering = useCallback(
    (value: string, columnId: string) => {

      setFilters((prevFilters) => {
        const updatedFilters = prevFilters.filter((filter) => filter.id !== columnId);
        if (value !== "") {
            updatedFilters.push({ id: columnId, value });
        }
        return updatedFilters;
      });
  },[dropdownValue, setFilters]);

  const getUniqueValues = (columnId: string) => {
    const uniqueValues = new Set<any>();
    const column = table.getAllColumns().find(col => col.id === columnId);

    if (column) {
      const columnDef = column.columnDef as { accessorKey?: string; accessorFn?: (original: any) => any };

      if (columnDef) {
        if (columnDef.accessorKey) {
          data.forEach(item => {
            const value = item[columnDef.accessorKey as keyof typeof item];
            if (value !== undefined && value !== null) { uniqueValues.add(value); }
          });
        }
        if (columnDef.accessorFn) {
          data.forEach(item => {
            const value = columnDef.accessorFn!(item);
            if (value !== undefined && value !== null) { uniqueValues.add(value); }
          });
        }
      }
    }

    return Array.from(uniqueValues);
  };


  const renderTablePagination = useMemo(() => {
    if(rows_displayed < data.length){
        return (<>
            <button onClick={() => table.setPageIndex(0)} disabled={!table.getCanPreviousPage()}> <span className="arrow-left"><span style={{fontSize : '10px', marginTop : '-8px', marginLeft : '-2px'}}>|</span></span> </button>
            <button onClick={() => table.previousPage()} disabled={!table.getCanPreviousPage()}> <span className="arrow-left"></span> </button>
            <p>{table.getState().pagination.pageIndex + 1}</p>
            <button onClick={() => table.nextPage()} disabled={!table.getCanNextPage()}> <span className="arrow-right"></span> </button>
            <button onClick={() => table.setPageIndex(table.getPageCount() - 1)} disabled={!table.getCanNextPage()}> <span className="arrow-right"><span style={{fontSize : '10px', marginTop : '-8px', marginLeft : '-2px'}}>|</span></span></button>
        </>);
    }
    else{
        return null;
    }
  }, [data.length, rows_displayed, table]);
  const renderedTableHeader = useMemo(() => {
    const headerGroups = table.getHeaderGroups();
    return (
      <thead>
        {headerGroups.map(headerGroup => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map(header => {
            const { column } = header;
            const columnId = column.id;
            const columnDef = column.columnDef;
            const filterFn = columnDef.filterFn;
            let filterValue = filters.find((filter) => filter.id === columnId)?.value as string || '';
            return (
              <th key={header.id}>
                <p className="table_header_text" onClick={header.column.getToggleSortingHandler()}>{flexRender(header.column.columnDef.header, header.getContext())}
                {{
                  asc: <span className='sorting_arrow arrow-up'></span>,
                  desc: <span className='sorting_arrow arrow-down'></span>,
                }[header.column.getIsSorted() as string] ?? null}</p>
                {header.column.getCanFilter() && (
                    filterFn === 'equals' ? (
                      <select value={filterValue} onChange={(e) => handleFiltering(e.target.value, columnId)}
                          placeholder={`Filter ${columnDef.header}`} >
                          <option value="">{dropdown_placeholder}</option>
                          {getUniqueValues(columnId).map((option) => (
                            <option key={option} value={option}>
                              {option}
                            </option>
                          ))}
                        </select>
                    ) : (
                      <input type="text" value={String(filters.find((filter) => filter.id === columnId)?.value || '')}
                        onChange={(e) => handleFiltering(e.target.value, columnId)} placeholder={`Filter ${columnDef.header}`} />
                    )
                  )}
              </th>
            )})}
          </tr>
        ))}
      </thead>
    );
  }, [table, filters, handleFiltering]);

return (
    <>
      <table key={tableName} className="custom-table">
        {renderedTableHeader}
        <tbody>
          {table.getRowModel().rows.map((row, index) => {
             const shouldExpand = expand_element && row.original && 'expand' in row.original && row.original.expand === true;
             let expandedContent = shouldExpand ? expand_element("", row.original) : null;

             if(expandedContent && typeof expandedContent === 'object' && typeof (expandedContent as Promise<JSX.Element>).then === 'function') {
                expandedContent = null;
             }

             return (
          <>
            <tr key={tableName +"-"+ index} onClick={() => handleRowClick && handleRowClick(row.original)} className="custom-table-row">
              {row.getVisibleCells().map(cell => (
                <td key={tableName +"-"+ cell.id}> {flexRender(cell.column.columnDef.cell, cell.getContext())} </td>
              ))}
            </tr>
            {expandedContent}
           </>
          )})}
        </tbody>
      </table>

      <div className="export_cont">
        <button className="export_csv_button" onClick={() => exportToCSV(data, columns)}>
            <FontAwesomeIcon icon="file-csv" />
        </button>
        <button className="export_excel_button" onClick={() => exportToExcel(data, columns)}>
            <FontAwesomeIcon icon="file-excel" />
        </button>
      </div>
      <div className="pagination">
          <div className="change_page_cont">
            {renderTablePagination}
          </div>
      </div>
    </>
  );
};

export default CustomTable;