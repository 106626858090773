import React, { useEffect, useState, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { post_data } from '../Javascript/API_Calls.js';
import { sendDataToParent } from '../Javascript/Page_JS/Iframe_Handler.js';
import { all_user_settings, convertToTimeZone } from '../Javascript/General.js';
import * as detail_js from '../Javascript/Page_JS/Details_Page.js';
import * as CH from '../Javascript/CoordinateHandling.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../CSS/Page_CS/details.css';
import IconDropdown from '../Components/IconDropdown';

interface Props {
  breadcrumbs: string;
  mobile_content: detail_js.detailsMobileContent;
  mobile_info: detail_js.mobileDetails;
}
const MobileDetails: React.FC<Props> = ({ breadcrumbs, mobile_content, mobile_info }) => {
    const [mobile_details, set_mobile_details] = useState<detail_js.mobileDetails | null>(null);
    const [mobileCoord, setMobileCoord] = useState<string[]>([]);
    const [printCoord, setPrintCoord] = useState<string>("");
    const [is_loading, set_is_loading] = useState(true);
    const [track_state, set_track_state] = useState<number|null>(null);
    const [trackingSettings, setTrackingSettings] = useState<any | null>(null);
    const [initialCoordFormat, setInitialCoordFormat] = useState<string>('D'); //all_user_settings;
    const dropdownOptions = [
        { value: mobile_details?.device_id+'|1hour', label: '60 minutes' },
        { value: mobile_details?.device_id+'|6hours', label: '6 hours' },
        { value: mobile_details?.device_id+'|12hours', label: '12 hours' },
        { value: mobile_details?.device_id+'|day', label: '24 hours' },
        { value: mobile_details?.device_id+'|custom_all', label: 'Custom Date' },
    ];
    const flyToMobile = async () =>{
        if(mobile_details?.device_id){
            sendDataToParent({ item: "mobile_fly_to", mobile_id: mobile_details.device_id});
        }
    }
    const removeMobileFromMap = async () =>{
        if(mobile_details?.device_id){
            sendDataToParent({ item: "mobile_remove_from_map", mobile_id: mobile_details.device_id});
        }
    }
    const sendMobileAlias = async () =>{
        if(mobile_details?.device_id){
            sendDataToParent({ item: "mobile_name", mobile_id:mobile_details?.device_id, mobile_name: mobile_details.device_name });
        }
    }
    const setMobileTrackState = async () =>{
        var should_be_paused = false;
        if(mobile_details?.date_received){
            var mobile_settings: any = trackingSettings;
            var current_time = new Date();
            var mobile_time = new Date(mobile_details?.date_received);
            var time_difference_ms = 0;

            const localTime = current_time.getTime()
            const localOffset = current_time.getTimezoneOffset() * 60 * 1000
            const utcTime = localTime + localOffset

            time_difference_ms = Math.abs(Math.floor(utcTime) - Math.floor(mobile_time.getTime()));
            var time_difference_minutes = Math.floor(time_difference_ms / (1000 * 60));
            if(mobile_settings !== null && mobile_settings?.['share_location']){
                if(mobile_settings?.['limit_tracking_time']){
                    if(current_time.getUTCHours() > mobile_settings?.['end_time_hours'] ||
                    (current_time.getUTCHours() === mobile_settings?.['end_time_hours'] && current_time.getUTCMinutes() > mobile_settings?.['end_time_minutes']) ||
                    current_time.getUTCHours() < mobile_settings?.['start_time_hours'] ||
                    (current_time.getUTCHours() === mobile_settings?.['start_time_hours'] && current_time.getUTCMinutes() < mobile_settings?.['start_time_minutes'])){
                        should_be_paused = true;
                    }
                }
                if(should_be_paused){
                    set_track_state(3);
                }else{
                    if(mobile_settings?.['position_period'] && mobile_settings?.['frequency']){
                        var expected_intervals = (detail_js.getExpectedPositionInterval(mobile_settings?.['frequency'], mobile_settings?.['position_period']) / 60);
                        var missed_positions = time_difference_minutes / (expected_intervals / 60);
                        if(mobile_settings?.['position_period'] && mobile_settings?.['frequency'] === 'PER_MINUTE'){
                            if(missed_positions > 600) set_track_state(4);
                            else if(missed_positions > 60) set_track_state(2);
                            else if(missed_positions > 30) set_track_state(1);
                            else set_track_state(0);
                        }else if(mobile_settings?.['position_period'] && mobile_settings?.['frequency'] === 'PER_HOUR'){
                            if(missed_positions > 300) set_track_state(4);
                            else if(missed_positions > 20) set_track_state(1);
                            else if(missed_positions > 10) set_track_state(2);
                            else set_track_state(0);
                        }else if(mobile_settings?.['position_period'] && mobile_settings?.['frequency'] === 'PER_DAY'){
                            if(missed_positions > 200) set_track_state(4);
                            else if(missed_positions > 4) set_track_state(1);
                            else if(missed_positions > 2) set_track_state(2);
                            else set_track_state(0);
                        }
                    }
                }
            }else if(mobile_settings === null && time_difference_ms !== 0){
                var expected_intervals = detail_js.getExpectedPositionInterval('PER_MINUTE', 1);
                var missed_positions = time_difference_minutes / (expected_intervals / 60);
                if(missed_positions > 1400) set_track_state(4);
                else if(missed_positions > 60) set_track_state(2);
                else if(missed_positions > 30) set_track_state(1);
                else set_track_state(0);
            } else{
                set_track_state(4);
            }
        }
    }
    const changeCoordinatesFormat = async (format_to: string, format_from: string) =>{
        if (mobileCoord && mobileCoord[0] && mobileCoord[1]) {
            const new_lat = CH.convertCoordinates(mobileCoord[0], true, format_to, format_from).toString();
            const new_lon = CH.convertCoordinates(mobileCoord[1], false, format_to, format_from).toString();

            setMobileCoord([new_lat, new_lon]);
        }
    }
    const generatePositionString = async () => {
       if(mobileCoord && mobileCoord[0] && mobileCoord[1] && mobileCoord[0] != "0.00" && mobileCoord[1] != "0.00"){
         setPrintCoord(mobileCoord[0] + ", " + mobileCoord[1]);
       }
       else{
         setPrintCoord("UNKNOWN");
       }
    }
    const update_alias = async () => {
        var new_alias = (document.getElementById("update_alias") as HTMLInputElement).value;

        var update_alias = {
            id : mobile_details?.device_id,
            new_alias : new_alias
        };

        const res = await post_data("/post_MercuryMobileAlias", update_alias);
        if(res?.function_status?.is_successful){
            mobile_info.device_name = new_alias;
            set_mobile_details(mobile_info);
            sendMobileAlias();
            document.getElementById("details_device_name")!.innerText = new_alias;
        }
        else{
            alert("An error occurred please try again");
        }
    };
    useEffect(() => {
      const fetchData = async () => {
        try {
          mobile_info.date_received = mobile_info.date_received.replace("T", " ");

          let remove_seconds = mobile_info.date_received.split(":");
          if (remove_seconds.length === 3) remove_seconds.pop();
          mobile_info.date_received = remove_seconds.join(":");

          set_mobile_details(mobile_info);

          if(mobile_details && mobile_details?.lat && mobile_details?.lon && printCoord === "" || printCoord === "UNKNOWN"){
            const lat: string = Number(mobile_details?.lat).toFixed(2).toString() ?? '';
            const lon: string = Number(mobile_details?.lon).toFixed(2).toString() ?? '';
            if (mobileCoord[0] !== lat || mobileCoord[1] !== lon) {
                setMobileCoord([lat, lon]);
            }
          }
          if(trackingSettings == null){
            const settings: any = await detail_js.getTrackingSettings(mobile_details?.device_id)
            setTrackingSettings(settings);
          }
        } catch (error) {
          console.error(error);
        } finally {
          if(mobile_details && mobileCoord && mobileCoord.length != 0){
            if(is_loading && initialCoordFormat != null && initialCoordFormat != 'D'){
              changeCoordinatesFormat(initialCoordFormat, '');
            }
            if(track_state == null || trackingSettings != null){
                setMobileTrackState();
            }
            generatePositionString();
            set_is_loading(false);
          }
        }
      };

      fetchData();
    }, [mobile_info, mobile_details, mobileCoord]);
    useEffect(() => {
        setMobileTrackState();
    }, [trackingSettings]);

    return (
    <>
    {!is_loading && (
        <div id="details_page" className="container-md mobile_details">
            <div className="modal-header">
                <i className="fa-solid fa-mobile-signal-out"></i> <p>{mobile_content?.details_mobile_tab_title ?? 'UNKNOWN'} - {mobile_details?.device_name ?? 'UNKNOWN'}</p>
            </div>
            <div className="modal-content">
                <div className="container-fluid">
                    <div className="content_header container-fluid">
                        <h5 id='details_device_name'>{mobile_details?.device_name ?? 'UNKNOWN'}</h5>
                        <div className="icon_container">

                            <IconDropdown breadcrumbs={breadcrumbs+"|MobileDetails"} iconClass="wave-triangle" options={dropdownOptions} call_back_func={detail_js.mobile_position_history}/>
                            <button className="btn fas pt1-5" onClick={flyToMobile} title="Centre map on vessel" value="mobile_find">
                                <FontAwesomeIcon icon="crosshairs"/>
                            </button>
                            <button className="btn fas pt1-5" onClick={removeMobileFromMap} title="Remove from map" value="mobile_mapRemove">
                                <FontAwesomeIcon icon="location-minus"/>
                            </button>
                        </div>
                        {track_state === 0 ? (
                            <span className="badge bg-success">Active</span>
                        ) : track_state === 1 ? (
                            <span className="badge bg-warning text-dark">Delayed</span>
                        ) : track_state === 2 ? (
                            <span className="badge bg-danger">Inactive</span>
                        ) : track_state === 3 ? (
                            <span className="badge bg-secondary">Paused</span>
                        ) : track_state === 4 ? (
                            <span className="badge bg-dark">Tracking Off</span>
                        ) : null }
                    </div>
                    <div className="tab_container container-fluid">
                        <div className="tab">
                            <p>{mobile_content?.details_mobile_tabs ?? 'UNKNOWN'}</p>
                        </div>
                    </div>
                    <div className="content_container container-fluid">
                        <div className="row">
                            <div className="col-4">
                                <p className="label">{mobile_content?.details_mobile_name ?? 'UNKNOWN'}:</p>
                            </div>
                            <div className="col-8">
                                <input id="update_alias" type="text" defaultValue={mobile_details?.device_name ?? 'UNKNOWN'} />
                                <button className="btn" onClick={update_alias} value="Update"><p>Update</p></button>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-4">
                                <p className="label">{mobile_content?.details_mobile_time ?? 'UNKNOWN'}:</p>
                            </div>
                            <div className="col-8">
                                <p className="text">{mobile_details?.date_received.replace("T", " ") ?? 'UNKNOWN'}</p>
                            </div>
                        </div>
                        {mobile_details?.terrestrial_location !== '' && (
                          <div className="row">
                            <div className="col-4">
                              <p className="label">{mobile_content?.details_mobile_land_location ?? 'UNKNOWN'}:</p>
                            </div>
                            <div className="col-8">
                              <p className="text">{mobile_details?.terrestrial_location}</p>
                            </div>
                          </div>
                        )}
                        {mobile_details?.maritime_location !== '' && (
                          <div className="row">
                            <div className="col-4">
                              <p className="label">{mobile_content?.details_mobile_maritime_location ?? 'UNKNOWN'}:</p>
                            </div>
                            <div className="col-8">
                              <p className="text">{mobile_details?.maritime_location}</p>
                            </div>
                          </div>
                        )}
                        <div className="row">
                            <div className="col-4">
                                <p className="label">{mobile_content?.details_mobile_position ?? 'UNKNOWN'}:</p>
                            </div>
                            <div className="col-8">
                                <p onClick={() => changeCoordinatesFormat('', '')} className="text">{printCoord ?? 'UNKNOWN'}</p>
                            </div>
                        </div>
                        <div className="row">
                          <div className="col-4">
                              <p className="label">{mobile_content?.details_mobile_id ?? 'UNKNOWN'}:</p>
                          </div>
                          <div className="col-8">
                              <p className="text">{mobile_details?.device_id ?? 'UNKNOWN'}</p>
                          </div>
                        </div>
                        <p></p>
                    </div>
                </div>
            </div>
          </div>
      )}
      </>
    );
};

export default MobileDetails;