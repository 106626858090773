import React, { useEffect, useState, useRef } from 'react';
import Popup from 'reactjs-popup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col, Accordion, Card, Button } from 'react-bootstrap';
import "../CSS/components.css";

interface Props {
  breadcrumbs: string;
  title: string[];
  text_content: React.ReactNode[];
  custom_buttons: React.ReactNode[] | null;
  notification_class: string[];
  call_back_func: ((item_id: string, more_info:string) => void) | null;
  on_close: (close_all: boolean, close_item:string) => void;
}

const CustomNotification: React.FC<Props> = ({breadcrumbs, title, text_content, custom_buttons, notification_class, call_back_func, on_close}) => {
    const [isOpen, setIsOpen] = useState(true); // State to control popup visibility
    const [titles, setTitles] = useState<string[]>(title);
    const [textContents, setTextContents] = useState<React.ReactNode[]>(text_content);
    const [notificationClasses, setNotificationClasses] = useState<string[]>(notification_class);

    const closePopup = (index: number) => {
        if (titles.length === 1) {
            on_close(false, "");
        }
        setTitles((prevTitles) => prevTitles.filter((_, i) => i !== index));
        setTextContents((prevContents) => prevContents.filter((_, i) => i !== index));
        setNotificationClasses((prevClasses) => prevClasses.filter((_, i) => i !== index));
    };

    const closeAllPopups = () => {
      on_close(true, "");
    };

    const handleCallBack = () => {
        if(call_back_func){
            call_back_func(new Date().toISOString(), breadcrumbs); // Trigger callback
        }
    }

    const copyToClipboard = () => {
      const content = text_content?.toString() || '';
      navigator.clipboard.writeText(content)
        .then(() => {
          alert('Text copied to clipboard!');
        })
        .catch((err) => {
          alert('Failed to copy text.');
          console.error(err);
        });
    };

    useEffect(() => {
    }, [titles, textContents, notificationClasses]);

    return (
      <Popup open={isOpen} closeOnDocumentClick={false}>
        {titles.map((current_title:string, i:number) => (
            <Container key={"notification_"+i} className={`popup-container ${notificationClasses[i]}`}>
              {/* Title */}
              <Container className="popup-header">
                <h3>{current_title}</h3>
                <div className="close_icon">
                    <Button onClick={() => closePopup(i)}><FontAwesomeIcon icon={['fas', 'xmark']} /></Button>
                </div>
              </Container>

              <Container className="popup-body">
                {textContents[i]}
              </Container>

              <Container className="popup-footer">
                {custom_buttons ? (
                    custom_buttons.map((button, index) => (
                       <div key={index}>{button}</div> // Add unique key here
                    ))
                ) : (
                <div>
                    <Button onClick={() => closePopup(i)} variant="light">Close Popup</Button>
                    <Button onClick={closeAllPopups} variant="light">Close All Popups</Button>
                    <Button onClick={copyToClipboard} variant="light">Copy Text</Button>
                </div>
                )}
              </Container>
            </Container>
        ))}
      </Popup>
    );
  };

export default CustomNotification;