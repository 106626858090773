import React, { useEffect, useState, useRef, useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import 'bootstrap/dist/css/bootstrap.min.css';

interface Props {
  breadcrumbs: string;
  id: string;
  initialState: number;
  three_states: boolean;
  disabled: boolean;
  onChange?: (state: 'unchecked' | 'indeterminate' | 'checked') => void; // Optional callback function
}

const CustomCheckbox: React.FC<Props> = ({ breadcrumbs, id, initialState, three_states, onChange, disabled}) => {
    const [checked, setChecked] = useState(false);
    const [indeterminate, setIndeterminate] = useState(false);
    const checkboxRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (checkboxRef.current) {
          checkboxRef.current.checked = checked;
          checkboxRef.current.indeterminate = indeterminate;
        }
    }, [checked, indeterminate]);

    useEffect(() => {
        if (initialState === 1) {
          setChecked(true);
          setIndeterminate(false);
        } else if (initialState === 2) {
          setChecked(false);
          setIndeterminate(true);
        } else {
          setChecked(false);
          setIndeterminate(false);
        }
    }, [initialState]);

    const handleClick = () => {
        if (indeterminate || !three_states) {
          setIndeterminate(false);
          setChecked(true);
          if (typeof onChange === 'function') {
              onChange('checked');
          }
        } else if (checked && three_states) {
          setChecked(false);
          setIndeterminate(true);
          if (typeof onChange === 'function') {
              onChange('indeterminate');
          }
        } else {
          setChecked(false);
          setIndeterminate(false);
          if (typeof onChange === 'function') {
              onChange('unchecked');
          }
        }
    };

    return (
    <>
        <input className="form-check-input" type="checkbox"
        id={id} ref={checkboxRef} onClick={handleClick} readOnly disabled={disabled}/>
    </>
    );
};

export default CustomCheckbox;