import React, { useEffect, useState, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { post_data } from '../Javascript/API_Calls.js';
import { sendDataToParent } from '../Javascript/Page_JS/Iframe_Handler.js';
import { all_user_settings, convertToTimeZone } from '../Javascript/General.js';
import SSASTab from './VesselDetailsTabs/SSASTab';

interface Props {
  breadcrumbs: string;
  display_tab: string;
}

const VesselDetails: React.FC<Props> = ({ breadcrumbs, display_tab}) => {

    useEffect(() => {
    },[breadcrumbs, display_tab])

    return (
        <div id="vessel_details">
            { display_tab === 'SSAS' ? (
                <SSASTab  breadcrumbs={breadcrumbs+"|VesselDetails"} />
            ) : null}
        </div>
    );
};

export default VesselDetails;