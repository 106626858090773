import React, { useEffect, useState, useRef, useMemo, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { createColumnHelper, ColumnDef, CellContext } from '@tanstack/react-table';
import * as SP from '../Javascript/Page_JS/SSAS_Page.js';
import { session_user_uuid, session_parent_uuid } from '../Javascript/session.js';
import { getUserUuid, getUTCTimeDifference } from '../Javascript/General.js';
import CustomTable from '../Components/CustomTable';
import { Container, Row, Col, Tabs, Tab, Button, Form, InputGroup } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../CSS/components.css';
import '../CSS/Page_CS/ssas_page.css';
import { matchSorter } from 'match-sorter';

interface Props {
  breadcrumbs: string;
}

const SSASPage: React.FC<Props> = ({ breadcrumbs }) => {
    const [userUuid, setUserUuid] = useState<string | null>(getUserUuid().toString());
    const [is_loading, set_is_loading] = useState(false);
    const [ssasContent, setSsasContent] = useState<SP.ssasContent>(SP.defaultSsasContent);
    const [allAlerts, setAllAlerts] = useState<SP.SSASAlert[] | null>(null);
    const [allArchivedAlerts, setAllArchivedAlerts] = useState<SP.SSASAlert[] | null>(null);

    const [activeAlerts, setActiveAlerts] = useState<SP.SSASAlert[] | null>(null);
    const [activeAlertsColumns, setActiveAlertsColumns] = useState<ColumnDef<SP.SSASAlert>[]>(SP.getAlertTableData({}, ssasContent.ssas_dashboard_tab_table1_column) as ColumnDef<SP.SSASAlert>[]);

    const [schedule, setSchedule] = useState<SP.SSASAlert[] | null>(null);
    const [scheduleColumns, setScheduleColumns] = useState<ColumnDef<SP.SSASAlert>[]>(SP.getScheduleTableData({}, true, {}, ssasContent.ssas_dashboard_tab_table2_column) as ColumnDef<SP.SSASAlert>[]);

    const checkboxContacts = (name:string, value:boolean) => {
        console.log('I '+name+'!', value);
    };
    const deleteContact = (row_id:number) => {
        alert('Delete contact: ' + row_id);
    };

    const toggleExpandTestSchedule = (row_id: string) => {
      if(testSchedule != null){
          setTestSchedule(prevData =>
            (prevData ?? []).map(test =>
              test.alert_uuid === row_id ? { ...test, expand: !test.expand } : test
            )
          );
      }
    };

    const [testSchedule, setTestSchedule] = useState<SP.SSASAlert[] | null>(null);
    const [testScheduleColumns, setTestScheduleColumns] = useState<ColumnDef<SP.SSASAlert>[]>(SP.getScheduleTableData({}, false, { toggleExpandTestSchedule }, ssasContent.ssas_schedule_tab_table_column) as ColumnDef<SP.SSASAlert>[]);


    const [affiliationList, setAffiliationList] = useState<SP.SSASAffiliation[] | null>(null);
    const [expandAlerts, setExpandAlerts] = useState<SP.SSASAlert[] | null>(null);
    const [contactsExpandColumns, setContactsExpandColumns] = useState<ColumnDef<SP.SSASAlert>[] | null>(null);
    const toggleExpandContact = (row_id: string) => {
      if (contacts != null) {
        setContacts(prevData =>
          (prevData ?? []).map(test =>
            test.contact_uuid === row_id ? { ...test, expand: !test.expand } : test
          )
        );
      }
    };

    const [contacts, setContacts] = useState<SP.SSASContact[] | null>(null);
    const [contactsColumns, setContactsColumns] = useState<ColumnDef<SP.SSASContact>[]>(SP.getContactsTableData({}, { toggleExpandContact, checkboxContacts, deleteContact }, ssasContent.ssas_contacts_tab_table_column) as ColumnDef<SP.SSASContact>[]);

    const [events, setEvents] = useState<SP.SSASEvent[] | null>(null);
    const [eventsColumns, setEventsColumns] = useState<ColumnDef<SP.SSASEvent>[]>(SP.getEventsLogsTableData({}, ssasContent.ssas_event_tab_table_column) as ColumnDef<SP.SSASEvent>[]);

    const [vessels, setVessels] = useState<SP.SSASAlert[] | null>(null);
    const [vesselsColumns, setVesselsColumns] = useState<ColumnDef<SP.SSASAlert>[]>(SP.getVesselsTableData({}, false, '', ssasContent.ssas_vessel_tab_table_column) as ColumnDef<SP.SSASAlert>[]);

    useEffect(() => {
        setActiveAlertsColumns(SP.getAlertTableData({}, ssasContent.ssas_dashboard_tab_table1_column) as ColumnDef<SP.SSASAlert>[]);
    },[activeAlerts]);
    useEffect(() => {
        setScheduleColumns(SP.getScheduleTableData({}, true, {}, ssasContent.ssas_dashboard_tab_table2_column) as ColumnDef<SP.SSASAlert>[]);
    },[schedule]);
    useEffect(() => {
        setTestScheduleColumns(SP.getScheduleTableData({}, false, {toggleExpandTestSchedule}, ssasContent.ssas_vessel_tab_table_column) as ColumnDef<SP.SSASAlert>[]);
    },[testSchedule]);
    useEffect(() => {
        setContactsColumns(SP.getContactsTableData({}, { toggleExpandContact, checkboxContacts, deleteContact }, ssasContent.ssas_contacts_tab_table_column) as ColumnDef<SP.SSASContact>[]);
    },[contacts]);
    useEffect(() => {
        setEventsColumns(SP.getEventsLogsTableData({}, ssasContent.ssas_event_tab_table_column) as ColumnDef<SP.SSASEvent>[]);
    },[events]);
    useEffect(() => {
        setVesselsColumns(SP.getVesselsTableData({}, false, '', ssasContent.ssas_vessel_tab_table_column) as ColumnDef<SP.SSASAlert>[]);
    },[vessels]);

    useEffect(() => {
        const fetchData = async () => {
           try {
             if(ssasContent.ssas_dashboard_tab_title === ""){
                const res = await SP.fetchSSASContent();
                setSsasContent(res);
             }
             if(allAlerts === null){
                const res_alerts = await SP.getAlertsData([], []);
                setAllAlerts(res_alerts);
             }
             if(allArchivedAlerts === null){
                const resArchAlerts = await SP.getAlertsArchiveData([],[]);
                setAllArchivedAlerts(resArchAlerts);
             }
             if(contacts === null){
                const resContact = await SP.getContactData([],[]);
                setContacts(resContact);
             }
             if(events === null){
                const resEvents = await SP.getEventData([],[]);
                setEvents(resEvents);
             }
             if(session_user_uuid === ""){
                getUserUuid();
             }
           } catch (error) {
             console.error(error);
           } finally {
             //if(allAlerts != null && allArchivedAlerts != null && contacts != null && events != null && ssasContent.ssas_dashboard_tab_title != ""){
             set_is_loading(false);
             //}
           }
         };
       fetchData();
    },[allAlerts, allArchivedAlerts, contacts, events, session_user_uuid, session_parent_uuid, ssasContent]);

    useEffect(()=>{
        if(allAlerts != null){
            let unique_vessels: SP.SSASAlert[] = [];
            let currently_active: SP.SSASAlert[] = [];
            let scheduled_today: SP.SSASAlert[] = [];
            let current_date = new Date();
            allAlerts.forEach((alert: SP.SSASAlert) =>{
                if(alert.schedule_date) {
                    let temp_date = new Date(alert.schedule_date);
                    if(alert.current_status.includes("FIRING")) currently_active.push(alert);
                    if(temp_date.getFullYear() === current_date.getUTCFullYear() && temp_date.getMonth() === current_date.getUTCMonth() &&
                        temp_date.getDate() === current_date.getUTCDate()) scheduled_today.push(alert);
                    if (!unique_vessels.find(x => x.vessels_uuid === alert.vessels_uuid)) unique_vessels.push(alert);
                }
            });
            setSchedule(scheduled_today);
            setVessels(unique_vessels);
            setActiveAlerts(currently_active)
        }
    }, [allAlerts]);
    useEffect(()=>{
        if(allArchivedAlerts != null) setTestSchedule(allArchivedAlerts);
    }, [allArchivedAlerts]);
    useEffect(()=>{
        const fetchData = async () => {
            if(contacts != null) {
                let contact_ids:string[] = contacts.map(contact => contact.contact_uuid);
                const res = await SP.getAffiliationData([],contact_ids,[]);
                setAffiliationList(res);
            }
        }
        fetchData();
    }, [contacts]);
    useEffect(()=>{
        if(affiliationList != null && contacts != null) {
            contacts.map(x => {
                const foundRelation = affiliationList?.filter(j => j.contact_uuid === x.contact_uuid);
                if (foundRelation) {
                    x.relation = foundRelation; // Only assign if a value is found
                }
            });
        }
    }, [affiliationList]);

    const expandElementContact = useCallback((row_id: string, more_info: any) => {
        if(more_info.expand){
            let alertIds = more_info.relation.map((i: SP.SSASAffiliation) => i.alert_uuid);
            if(allAlerts != null){
                const relatedAlerts: SP.SSASAlert[] = allAlerts.filter(i => alertIds.includes(i.alert_uuid));
                if(relatedAlerts.length > 0 ){
                    return (<CustomTable breadcrumbs={breadcrumbs+"|SSAS_Page|ContactExpandTable"} columns={activeAlertsColumns} data={relatedAlerts} rows_displayed={relatedAlerts.length} dropdown_placeholder={"All"} expand_element={null} />);
                }
                else {
                    return null;
                }
            }
        }
    }, [allAlerts]);

    const expandElementTestSchedule = useCallback((row_id: string, more_info: any) => {
      if(more_info.expand){
          return (
          <tr>
            <td></td>
            <td colSpan={5}>
              <Container className="test_schedule_expand">
                <Row>
                    <Col>
                        <p className="text">Started (UTC):</p>
                    </Col>
                    <Col>
                        <Form.Group className="mb-3" controlId="testSchedule.startedUTC">
                            <Form.Control type="text" placeholder="Started (UTC)" />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Button variant="primary"></Button>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <p className="text">Started by:</p>
                    </Col>
                    <Col>
                        <Form.Group className="mb-3" controlId="testSchedule.startedBy">
                            <Form.Control type="text" placeholder="Started by" />
                        </Form.Group>
                    </Col>
                    <Col>
                    </Col>
                </Row>
                <Row>
                    <Col>
                       <p className="text">Completed:</p>
                    </Col>
                    <Col>
                        <Form.Group className="mb-3" controlId="testSchedule.completed">
                            <Form.Control type="text" placeholder="Completed" />
                        </Form.Group>
                    </Col>
                    <Col>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <p className="text">Completed by:</p>
                    </Col>
                    <Col>
                        <Form.Group className="mb-3" controlId="testSchedule.completedBy">
                            <Form.Control type="text" placeholder="Completed by" />
                        </Form.Group>
                    </Col>
                    <Col>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <p className="text">Notes:</p>
                    </Col>
                    <Col>
                        <Form.Group className="mb-3" controlId="testSchedule.notes">
                            <Form.Control type="text" placeholder="Notes" />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Button variant="light"></Button>
                    </Col>
                </Row>
              </Container>
            </td>
          </tr>);
        }
        else {
            return (null);
        }
    }, []);

    return (
      <Container id="ssas_page">
        <div className="header">
            <h1>SSAS</h1>
        </div>
        {is_loading ? (
            <p>Loading...</p>
        ) : (
            <Tabs defaultActiveKey="dashboard" id="ssas_tab_cont">
                <Tab eventKey="dashboard" title={ssasContent.ssas_dashboard_tab_title}>
                    <Container id="ssas_tab_cont-dashboard">
                        <Row>
                          <Col>
                            <h2>{ssasContent.ssas_dashboard_tab_table1_title}</h2>
                            {activeAlerts && (
                                <CustomTable breadcrumbs={breadcrumbs+"|SSAS_Page|" + ssasContent.ssas_dashboard_tab_table1_title} columns={activeAlertsColumns} data={activeAlerts} rows_displayed={10} dropdown_placeholder={"All"} expand_element={null} />
                            )}
                          </Col>
                          <Col>
                            <h2>{ssasContent.ssas_dashboard_tab_table2_title}</h2>
                            {schedule && (
                                <CustomTable breadcrumbs={breadcrumbs+"|SSAS_Page|" + ssasContent.ssas_dashboard_tab_table2_title} columns={scheduleColumns} data={schedule} rows_displayed={schedule.length} dropdown_placeholder={"All"} expand_element={null} />
                            )}
                          </Col>
                        </Row>
                    </Container>
                </Tab>
                <Tab eventKey="test_schedule" title={ssasContent.ssas_schedule_tab_title}>
                    <Container>
                        <Row>
                          <div className="col">
                            <h2>{ssasContent.ssas_schedule_tab_title}</h2>
                            {testSchedule && (
                                <CustomTable breadcrumbs={breadcrumbs+"|SSAS_Page|" + ssasContent.ssas_schedule_tab_title} columns={testScheduleColumns} data={testSchedule} rows_displayed={testSchedule.length} dropdown_placeholder={"All"} expand_element={expandElementTestSchedule} />
                            )}
                          </div>
                        </Row>
                    </Container>
                </Tab>
                <Tab eventKey="contacts" title={ssasContent.ssas_contacts_tab_title}>
                    <Container>
                        <Row>
                          <div className="col">
                            <h2>{ssasContent.ssas_contacts_tab_title}</h2>
                            {contacts && (
                                <CustomTable breadcrumbs={breadcrumbs+"|SSAS_Page|ContactsTable"} columns={contactsColumns} data={contacts} rows_displayed={contacts.length} dropdown_placeholder={"All"} expand_element={expandElementContact} />
                            )}
                          </div>
                        </Row>
                    </Container>
                </Tab>
                <Tab eventKey="event_logs" title={ssasContent.ssas_event_tab_title}>
                    <Container>
                        <Row>
                          <div className="col">
                            <h2>{ssasContent.ssas_event_tab_title}</h2>
                            {events && (
                                <CustomTable breadcrumbs={breadcrumbs+"|SSAS_Page|EventLogsTable"} columns={eventsColumns} data={events} rows_displayed={events.length} dropdown_placeholder={"All"} expand_element={null} />
                            )}
                          </div>
                        </Row>
                    </Container>
                </Tab>
                <Tab eventKey="vessels" title={ssasContent.ssas_vessel_tab_title}>
                    <Container>
                        <Row>
                          <div className="col">
                            <h2>{ssasContent.ssas_vessel_tab_title}</h2>
                            {vessels && (
                                <CustomTable breadcrumbs={breadcrumbs+"|SSAS_Page|VesselsTable"} columns={vesselsColumns} data={vessels} rows_displayed={vessels.length} dropdown_placeholder={"All"} expand_element={null} />
                            )}
                          </div>
                        </Row>
                    </Container>
                </Tab>
            </Tabs>
        )}
      </Container>
    );
};

export default SSASPage;