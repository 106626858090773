import React, { useEffect, useState, useRef, useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { format, parseISO } from 'date-fns';
import { post_data } from '../../Javascript/API_Calls.js';
import { sendDataToParent } from '../../Javascript/Page_JS/Iframe_Handler.js';
import { getAlertStatusElement } from '../../Javascript/Page_JS/SSAS_Page.js';
import { all_user_settings, convertToTimeZone } from '../../Javascript/General.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col, Accordion, Card, Button, Form } from 'react-bootstrap';
import CustomNotification from '../../Components/CustomNotification'

interface Props {
  breadcrumbs: string;
}
const VesselDetails: React.FC<Props> = ({ breadcrumbs}) => {
    const [activeKey, setActiveKey] = useState<string | null>(null); // TypeScript-safe useState
    const [vesselSSASActiveState, setVesselSSASActiveState] = useState<string>("");
    const [tempSwitch, setTempSwitch] = useState<boolean>(false);
    const [notificationTitle, setNotificationTitle] = useState<string[]>([]);
    const [notificationClass, setNotificationClass] = useState<string[]>([]);
    const [notificationContent, setNotificationContent] = useState<React.ReactNode[]>([]);

    const toggleTab = (tab: string) => {
        setActiveKey(activeKey === tab ? null : tab); // Toggle tab open/close
    };

    const handleSSASActiveButton = (event: React.MouseEvent<HTMLButtonElement>) => {
        const button = event.currentTarget;
        console.log(button.value);
        if(vesselSSASActiveState === "") setVesselSSASActiveState("0");
        if(vesselSSASActiveState === "0") setVesselSSASActiveState("1");
        if(vesselSSASActiveState === "1") setVesselSSASActiveState("2");
        if(vesselSSASActiveState === "2") setVesselSSASActiveState("3");
        //if(vesselSSASActiveState === "3") setVesselSSASActiveState("4");
        if(vesselSSASActiveState === "3") setVesselSSASActiveState("");
    };

    const SSASActiveState = useMemo(() => { //onClick={() => toggleTab('1')}
        if(vesselSSASActiveState === "SSAS ACTIVATION REQUESTED" || vesselSSASActiveState === "0"){
            return (
                <Button className="SSAS_activate_button" variant="secondary" value="cancel" onClick={handleSSASActiveButton}>Cancel SSAS Request</Button>
            );
        }
        else if(vesselSSASActiveState === "SSAS ACTIVE" || vesselSSASActiveState === "1"){
            return (
                <Button className="SSAS_activate_button" variant="light" value="deactivate" onClick={handleSSASActiveButton}>Request SSAS Deactivation</Button>
            );
        }
        else if(vesselSSASActiveState === "SSAS FIRING" || vesselSSASActiveState === "2"){
            return (<>
                <Button className="SSAS_activate_button" variant="info" value="mute" onClick={handleSSASActiveButton}>Mute Alarm</Button>
                <Button className="SSAS_activate_button" variant="secondary" value="cancel" onClick={handleSSASActiveButton}>Cancel SSAS Alert</Button>
            </>);
        }
        else if(vesselSSASActiveState === "SSAS FIRING IN TEST MODE" || vesselSSASActiveState === "3"){
            return (
                <Button className="SSAS_activate_button" variant="info" value="mute" onClick={handleSSASActiveButton}>Mute Alarm</Button>
            );
        }
        else if(vesselSSASActiveState === "SSAS IN TEST MODE" || vesselSSASActiveState === "4"){
                    return null;
        }else{
            return (
                <Button className="SSAS_activate_button" variant="light" value="activate" onClick={handleSSASActiveButton}>Request SSAS Activation</Button>
            );
        }
    }, [vesselSSASActiveState]);

    const [isPopupOpen, setIsPopupOpen] = useState(false); // State to control popup visibility
    const test_notification = () => {
        if(notificationTitle.length === 0){
            const current_date: string = new Date().toISOString();
            const formattedDate = format(new Date(current_date), "MMMM do yyyy, HH:mm 'UTC'");

            const addContent = (
                <p className="text">
                    TEST MODE -- TEST MODE -- TEST MODE <br /><br />
                    {formattedDate}<br /><br />
                    CLICK <a href="#" onClick={(e) => { e.preventDefault(); test_notification_callback("test_item", "testing"); }}>
                        HERE
                    </a> TO OPEN LATEST SSAS MESSAGE, LOCATE VESSEL, AND ACKNOWLEDGE THIS MESSAGE.<br /><br />
                    TEST MODE -- TEST MODE -- TEST MODE
                </p>
            );

            setNotificationClass(prevClasses => [...prevClasses, "notification_SSAS_test"]);
            setNotificationTitle(prevTitles => [...prevTitles, "TEST MODE - SSAS FIRING FOR VESSEL: SAMPLE VESSEL"]);
            setNotificationContent(prevContents => [...prevContents, addContent]);

            const addContent2 = (
                <p className="text">
                    {formattedDate}<br /><br />
                    SSAS HAS BEEN ACTIVATED FOR VESSEL: SAMPLE VESSEL<br /><br />
                    CLICK <a href="#" onClick={(e) => { e.preventDefault(); test_notification_callback("test_item", "testing"); }}>
                        HERE
                    </a> TO OPEN LATEST SSAS MESSAGE, LOCATE VESSEL, AND ACKNOWLEDGE THIS MESSAGE.<br /><br />
                </p>
            );

            setNotificationClass(prevClasses => [...prevClasses, "notification_SSAS_alert"]);
            setNotificationTitle(prevTitles => [...prevTitles, "SSAS FIRING FOR VESSEL: SAMPLE VESSEL"]);
            setNotificationContent(prevContents => [...prevContents, addContent2]);
        }
        setIsPopupOpen(true);
    };
    const test_notification_close = (close_all: boolean, close_item:string) => {
        if(close_all){
            alert("close all");
        }
        setIsPopupOpen(false);
    }
    const test_notification_callback = (item_id: string, more_info: string) => {
        alert("test: " + item_id);
    }

    useEffect(() => {
    }, [isPopupOpen]);

    const [alertTitle, setAlertTitle] = useState<string[]>([]);
    const [alertClass, setAlertClass] = useState<string[]>([]);
    const [alertContent, setAlertContent] = useState<React.ReactNode[]>([]);
    const [alertButtons, setAlertButtons] = useState<React.ReactNode[]>([]);
    const [isPopupAlert, setIsPopupAlert] = useState(false); // State to control popup visibility
    const test_alert = () => {
        if(alertTitle.length === 0){
            setAlertTitle(prev => [...prev, "Record Alert Details"]);
            setAlertClass(prev => [...prev, "record_SSAS_alert"]);
            const alert_content = (
                <>
                    <p className="text">Please record any alert notes here</p>

                    <Form.Control as="textarea" placeholder="Enter notes here"/>
                </>
            );
            setAlertContent(prev => [...prev, alert_content]);
            const alert_button = (
                <>
                    <Button variant="success" onClick={(e) => handleDetailSave(e)}>Save</Button>
                </>
            );
            setAlertButtons(prev => [...prev, alert_button]);
        }
        setIsPopupAlert(true);
    }
    const alertClose = () => {
        setIsPopupAlert(false);
    }
    const handleDetailSave = (event: React.MouseEvent<HTMLButtonElement>) => {
        let currentElement = event.currentTarget.parentElement;
        let i = 0;
        while (currentElement && i < 5) {
            if (currentElement.classList.contains('popup-container')) {
              break; // Stop when the element with the desired class is found
            }
            currentElement = currentElement.parentElement; // Move to the next parent
            i++;
        }
        if (currentElement && currentElement.classList.contains('popup-container')) {
          // Find the textarea within the parent container
          const textarea = currentElement.querySelector('textarea');
          if (textarea) {
            const textareaValue = textarea.value;
            console.log('Saved textarea content:', textareaValue);
            // Perform actions with the textarea value (e.g., save to state, send to API)
          }
        }
    };
    useEffect(() => {
    }, [isPopupAlert]);
    const [testTitle, setTestTitle] = useState<string[]>([]);
    const [testClass, setTestClass] = useState<string[]>([]);
    const [testContent, setTestContent] = useState<React.ReactNode[]>([]);
    const [testButtons, setTestButtons] = useState<React.ReactNode[]>([]);
    const [isPopupTest, setIsPopupTest] = useState(false); // State to control popup visibility
    const test_test = () => {
        if(testTitle.length === 0){
            setTestTitle(prev => [...prev, "Record Alert Details"]);
            setTestClass(prev => [...prev, "record_SSAS_alert"]);
            const test_content = (
                <>
                    <p className="text">Please record any notes regarding the test here, then select one of the buttons below</p>

                    <Form.Control as="textarea" placeholder="Enter notes here"/>
                </>
            );
            setTestContent(prev => [...prev, test_content]);
            const test_button = (
                <>
                    <Button variant="secondary">Not Applicable</Button>
                    <Button variant="danger">Unsuccessful Test</Button>
                    <Button variant="success">Successful Test</Button>
                </>
            );
            setTestButtons(prev => [...prev, test_button]);
        }
        setIsPopupTest(true);
    }
    const testClose = () => {
        setIsPopupTest(false);
    }
    useEffect(() => {
    }, [isPopupTest]);
    /*
    const [testSchedule, setTestSchedule] = useState<SP.SSASDashboardData[] | null>(null);
    const [contacts, setContacts] = useState<SP.SSASContact[] | null>(null);
    const [events, setEvents] = useState<SP.SSASEvent[] | null>(null);

    const [testScheduleColumns, setTestScheduleColumns] = useState<ColumnDef<SP.SSASDashboardData>[]>(SP.getScheduleTableData({}, false, { toggleExpandTestSchedule }) as ColumnDef<SP.SSASDashboardData>[]);
    const [eventsColumns, setEventsColumns] = useState<ColumnDef<SP.SSASEvent>[]>(SP.getEventsLogsTableData({}) as ColumnDef<SP.SSASEvent>[]);
    const [contactsColumns, setContactsColumns] = useState<ColumnDef<SP.SSASContact>[]>(SP.getContactsTableData({}, { toggleExpandContact, checkboxContacts, deleteContact }) as ColumnDef<SP.SSASContact>[]);
    */

    return (
      <div id="details_SSAS_tab" >
          {isPopupOpen && (
            <CustomNotification breadcrumbs="Home > Profile" title={notificationTitle} text_content={notificationContent} custom_buttons={null}
                            notification_class={notificationClass} call_back_func={test_notification_callback} on_close={test_notification_close} />
          )}
          {isPopupAlert && (
            <CustomNotification breadcrumbs="Home > Profile" title={alertTitle} text_content={alertContent} custom_buttons={alertButtons}
                              notification_class={alertClass} call_back_func={null} on_close={alertClose} />
            )}
          {isPopupTest && (
            <CustomNotification breadcrumbs="Home > Profile" title={testTitle} text_content={testContent} custom_buttons={testButtons}
                                          notification_class={testClass} call_back_func={null} on_close={testClose} />
          )}
          <Container className="initial_content" fluid>
            <Row>
              <Col>
                <p>STATUS: {getAlertStatusElement('NOT ENABLED')}</p>
              </Col>
              <Col>
                { SSASActiveState }
              </Col>
            </Row>
            <Row>
              <div className="text_cont">
                <p>Email Address: </p>
                <Button className="copy" variant="light"><FontAwesomeIcon icon={['far', 'copy']} /></Button>
                <p>n/a</p>

                <Button onClick={test_notification} variant="light">test notification</Button>
                <Button onClick={test_alert} variant="light">test alert</Button>
                <Button onClick={test_test} variant="light">test test</Button>
              </div>
            </Row>
          </Container>
        <Accordion activeKey={activeKey}>
          <Card>
            <Accordion.Item eventKey="0">
              <Accordion.Header onClick={() => toggleTab('0')}>Event Log</Accordion.Header>
              <Accordion.Body>
                Dashboard content goes here.
              </Accordion.Body>
            </Accordion.Item>
          </Card>

          <Card>
            <Accordion.Item eventKey="1">
              <Accordion.Header onClick={() => toggleTab('1')}>Call Cascade</Accordion.Header>
              <Accordion.Body>
                Profile content goes here.
              </Accordion.Body>
            </Accordion.Item>
          </Card>

          <Card>
            <Accordion.Item eventKey="2">
              <Accordion.Header onClick={() => toggleTab('2')}>Alert Recipients</Accordion.Header>
              <Accordion.Body>
                Settings content goes here.
              </Accordion.Body>
            </Accordion.Item>
          </Card>

          <Card>
            <Accordion.Item eventKey="3">
              <Accordion.Header onClick={() => toggleTab('3')}>Test Schedules</Accordion.Header>
              <Accordion.Body>
                Help content goes here.
              </Accordion.Body>
            </Accordion.Item>
          </Card>
        </Accordion>
      </div>
    );
};

export default VesselDetails;