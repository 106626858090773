import {sendDataToParent} from './Iframe_Handler.js';
import {getUserSettings} from '../General.js';
import CustomDatePicker from '../../Components/CustomDatePicker';
import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';

export var current_tracking_settings = [];
/* Objects */
export type detailsMobileContent = {
  details_mobile_id: string;
  details_mobile_name: string;
  details_mobile_position: string;
  details_mobile_time: string;
  details_mobile_tab_title: string;
  details_mobile_tabs: string;
  details_mobile_land_location: string;
  details_mobile_maritime_location: string;
}
export const default_details_mobile_content: detailsMobileContent = {
  details_mobile_id: "",
  details_mobile_name: "",
  details_mobile_position: "",
  details_mobile_time: "",
  details_mobile_tab_title: "",
  details_mobile_tabs: "",
  details_mobile_land_location: "",
  details_mobile_maritime_location: "",
};

export type mobileDetails = {
    device_id: string;
    device_name: string;
    date_received: string;
    terrestrial_location: string;
    maritime_location: string;
    lat: string;
    lon: string;
}
export const default_mobileDetails: mobileDetails = {
    device_id: "",
    device_name: "",
    date_received: "",
    terrestrial_location: "",
    maritime_location: "",
    lat: "",
    lon: "",
  }


/* Functions */
export function mobile_position_history(container_id, selected, breadcrumbs){
    if(breadcrumbs.toUpperCase().includes("IFRAME")){
        if(selected.includes("custom")){
            var container_element = document.getElementById(container_id);
            var parent = container_element.parentElement;

            let popup_overlay = document.createElement('div');
            popup_overlay.className = 'overlay';
            popup_overlay.id = 'mobile_position_history';
            popup_overlay.onclick = function() {
                popup_overlay.remove();
            };

            let popup_content = document.createElement('div');
            popup_content.className = 'centered-content';
            popup_content.onclick = function(e) {
                 e.stopPropagation();
             };
            let customDatePickerContainer = document.createElement('div');

            const root = createRoot(
                customDatePickerContainer
            );
            const today = new Date();
            root.render(<CustomDatePicker
                breadcrumbs="breadcrumbs"
                startDate={null}
                endDate={null}
                minimumDate={null}
                maximumDate={today.toISOString()}
                range={true}
                include_time={false}
                call_back_func={(returnDate, breadcrumbs) => {
                    let popup_overlay = document.getElementById('mobile_position_history');
                    popup_overlay.remove();

                    sendDataToParent({ item: "mobile_details", mobile_history: selected, Dates: returnDate});
                }}
            />);

            popup_content.appendChild(customDatePickerContainer);
            popup_overlay.appendChild(popup_content);
            parent.appendChild(popup_overlay);
        }
        else{
            sendDataToParent({ item: "mobile_details", mobile_history: selected});
        }
    }
}
export function getExpectedPositionInterval(frequency, period) {
   const secondsInMinute = 60;
   const secondsInHour = 3600;
   const secondsInDay = 86400;

   switch(frequency) {
       case 'PER_MINUTE':
           return secondsInMinute / period;
       case 'PER_HOUR':
           return secondsInHour / period;
       case 'PER_DAY':
           return secondsInDay / period;
       default:
           throw new Error('Invalid frequency');
   }
}
export async function getTrackingSettings(ffid) {
    var temp = await getUserSettings(ffid, "mobile_settings", true);
    //if (!all_user_settings.some(innerArray => typeof innerArray[0] === 'string' && innerArray[0].includes("mobile_settings"))){
    return temp;
    //}
}